<template>
    <base-section id="marketing">
        <v-container>
            <v-row>
                <v-col cols="3" class="text-center hidden-sm-and-down">
                    <template v-for="(card, i) in cards">
                        <base-avatar-card
                            :key="card.title"
                            align="center"
                            v-bind="card"
                        />

                        <v-divider
                            v-if="i + 1 !== cards.length"
                            :key="`divider-${i}`"
                            class="my-8"
                        />
                    </template>
                </v-col>

                <v-col cols="2" class="hidden-sm-and-down text-center">
                    <v-responsive height="calc(100% - 16px)" class="mt-2">
                        <v-divider vertical />
                    </v-responsive>
                </v-col>

                <v-col cols="12" md="7">
                    <base-subheading
                        size="display-1"
                        title="TENANT SCREENING"
                        space="0"
                    />

                    <base-title
                        class="primary--text"
                        title="SERVICES"
                        tag="div"
                    />

                    <base-body>
                        Easy-to-read, report from TransUnion, which includes a
                        credit check, their criminal history, and a list of past
                        evictions. Additional reference and property specific
                        criteria review. Our background check searches over 370
                        million records from all 50 states and our eviction
                        report compares over 27 million records from all 50
                        states. It is a soft inquiry as well, meaning it won't
                        negatively affect the renter's credit score. The Rent
                        Well Program offers self screening and barriers to
                        housing review.
                    </base-body>

                    <v-row justify="center">
                        <v-col cols="12" md="6">
                            <base-img
                                :aspect-ratio="1.0"
                                :src="require(`@/assets/info-graphic.jpg`)"
                                :height="
                                    $vuetify.breakpoint.smAndDown ? 320 : 480
                                "
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </base-section>
</template>

<script>
export default {
    name: "SectionMarketing",

    data: () => ({
        cards: [
            {
                title: "Appplication Self Screening",
                icon: "mdi-domain",
                outlined: true,
            },
            {
                title: "Barrier Removal",
                color: "primary",
                dark: true,
                icon: "mdi-home-city",
            },
            {
                title: "Secure Housing",
                icon: "mdi-clipboard-account",
                outlined: true,
            },
        ],
        marketing: [
            "Search Engine optimization",
            "Virtual Marketing",
            "Content Management",
            "Virtual Marketing",
            "Keyword Analysis",
            "Content Management",
        ],
    }),
};
</script>
